export type AssessmentStructure = {
  assessmentStructureId: string;
  sourceAssessmentId: string;
  addedTs: string;
};

export type IReportLeaderType = {
  generateReports: { name: string; email: string }[];
  notGenerateReports: string[];
};

export type Assessments = {
  assessmentStructureId?: string;
  assignmentStatus?: string;
  id?: string;
  assessmentId?: string;
  appraisalStatus?: string;
  applicationId?: string;
  completion?: string;
};

export type AssignmentDetails = {
  addedTs: string;
  assessments: Assessments[];
  id: string;
  isManualAssigned: boolean;
  lastUpdatedTs: string;
  leaderEmail: string;
  leaderFirstName: string;
  leaderLastName: string;
  leaderProfileId: string;
  reportAttachments?: string;
};

export type IProjectData = {
  name: string;
  clientId: string;
  status: string;
  projectLeadName: string;
  projectLeadUserId: string;
  purpose: string;
  reference: string;
  startDate: string;
  closeDate: string;
  assessmentStructures: AssessmentStructure[];
  assignments?: AssignmentDetails[];
  insightsProject?: {
    status: string;
    id: string;
    title: string;
    sourceProjectId: string;
    reference: string;
    projectStatus: string;
    syncEnabled: boolean;
    addedTs: string;
    lastUpdatedTs: string;
  };
  assignmentStats: {
    leaders: number;
    'Not Started': number;
    'Not Invited': number;
    'In Progress': number;
    Completed: number;
  };
  id: string;
  aggregatedStatus?: string;
  hiringManager?: string;
};

export type IProjectsResponse = {
  status: string;
  code: number;
  data: IProjectData[];
};

export type IAddNewProjectRequest = {
  id?: string;
  name?: string;
  clientId?: string;
  status?: string;
  projectLeadName?: string;
  reference?: string;
  purpose?: string;
  startDate?: string;
  closeDate?: string;
  assignments?: object;
  projectId?: string;
  hiringManager?: string;
};

export type AssessmentsTable = {
  id: string;
  assessment: string;
  skillCategory: string;
  type: string;
  staged: number;
  assigned: number;
  inProgress: number;
  completed: number;
};

export type AssessmentLeadersTable = {
  id?: string;
  leaderName?: string;
  roleName?: string;
  pAssessor?: string;
  cAssessor?: string;
  cbiStatus?: string;
  projectAssessmentStatus?: string;
  resultStatus?: string;
  assessmentId?: string;
  status?: string;
};

// Project Leaders Report Data type
export type IReportsTypeData = {
  tableName: string;
  originalName: string;
};
export type IAssignmentAssessmentType = {
  assessmentStructureId: string;
  assessmentId?: string;
  assignmentStatus: string;
};

export type IProjectAssignmentType = {
  leaderProfileId: string;
  leaderEmail: string;
  leaderFirstName: string;
  leaderLastName: string;
  isManualAssigned: boolean;
  assessments?: Array<IAssignmentAssessmentType>;
  addedTs: string;
  lastUpdatedTs: string;
  id: string;
};

export type IGenerateReportPayload = {
  participantEmail: string;
  accountID: string;
  projectID: string;
  clientId?: string;
  reportOptions?: Array<string>;
};

export interface IAssignmentData {
  participant: string;
  email?: string;
  assessment: string;
  project: string;
  projectStartDate: string;
  projectEndDate: string;
  type: string;
  status: string;
  id: string;
  raters: any[];
  completedDate: string;
  completedPercentage: string;
  lastReminderSent: string;
  reminderCount: string;
  raterTypes?: any[];
}

export const getQuantity = (ratertype: string, raters: any[]) => {
  const quantity =
    raters?.reduce((acc, el) => {
      if (el['type'] === ratertype && el['status'] === 'Completed') {
        return acc + 1;
      }
      return acc;
    }, 0) || 0;
  return quantity;
};
