import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  IAddNewProjectRequest,
  IGenerateReportPayload,
  IProjectsResponse,
} from './types/projects';
import {
  IAddProjectResponse,
  IGenericResponse,
  assessmentStatusData,
} from '../../commons/types';

export const projectsApi = createApi({
  reducerPath: 'projects/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_V2_API_KEY}projects/api/v2/`,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        .accessToken.accessToken;
      const authZToken = localStorage.getItem('authzToken') || '';
      if (authZToken.length) headers.set('X-Auth-Z', authZToken);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['projects', 'project', 'Get All Assignments'],
  endpoints: (builder) => ({
    getProjectsByClientId: builder.query<IProjectsResponse, string>({
      query: (clientId) => `projects?clientId=${clientId}`,
      providesTags: ['projects'],
    }),
    getProjectById: builder.query<IProjectsResponse, string>({
      query: (projectId) => `projects/${projectId}`,
      providesTags: ['project'],
    }),
    setProject: builder.mutation<IGenericResponse, IAddNewProjectRequest>({
      query: (payload) => ({
        url: 'projects',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['projects'],
    }),
    updateProject: builder.mutation<IGenericResponse, IAddNewProjectRequest>({
      query: ({ projectId, ...payload }) => ({
        url: `projects/${projectId}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['project', 'projects'],
    }),
    // Assignment API
    addProjectLeader: builder.mutation<
      IAddProjectResponse,
      { projectId: string; leaderProfileId: string }
    >({
      query: ({ projectId, leaderProfileId }) => ({
        url: `assignments?projectId=${projectId}&leaderProfileId=${leaderProfileId}`,
        method: 'POST',
      }),
      invalidatesTags: ['project'],
    }),

    updateAssignmentStatus: builder.mutation<
      IGenericResponse,
      assessmentStatusData
    >({
      query: ({ assignmentId, ...restPayload }) => ({
        url: `assignments/${assignmentId}`,
        method: 'PATCH',
        body: restPayload,
      }),
      invalidatesTags: ['project'],
    }),
    // Assignments APIs
    removeLeader: builder.mutation<
      IGenericResponse,
      {
        assignmentId: string;
        projectId: string | undefined;
        leaderProfileId: string;
      }
    >({
      query: (payload) => ({
        url: `assignments/${payload.assignmentId}?projectId=${payload.projectId}&leaderProfileId=${payload.leaderProfileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['projects', 'project'],
    }),

    // Report Generation
    generateReport: builder.mutation<IGenericResponse, IGenerateReportPayload>({
      query: (payload) => ({
        url: 'generate-report',
        method: 'POST',
        body: payload,
      }),
    }),

    getProjectStats: builder.query<any, string>({
      query: (params) => `project-stat?${params}`,
    }),
    // Get All Assignments
    getAllAssignments: builder.query<IProjectsResponse, string>({
      query: (id) => ({
        url: `get-assignment?${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetProjectsByClientIdQuery,
  useGetProjectByIdQuery,
  useSetProjectMutation,
  useUpdateProjectMutation,
  useAddProjectLeaderMutation,
  useUpdateAssignmentStatusMutation,
  useRemoveLeaderMutation,
  useGenerateReportMutation,
  useGetProjectStatsQuery,
  useLazyGetProjectStatsQuery,
  useGetAllAssignmentsQuery,
  useLazyGetAllAssignmentsQuery,
} = projectsApi;

// Invalidates tags of Project API in other API
export const projectApiInvalidateTags = projectsApi.util.invalidateTags;
