import { Box, Container, Typography, styled, BoxProps } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import Button from '../atoms/button/Button';
import SubTitle from '../atoms/title/SubTitle';
import HDSpinner from '../hd-ui-kit/HDSpinner';
import { useActions } from '../hooks/actions';
import FormLabelDropdown from '../molecules/form/FormLabelDropdown';
import FormLabelInput from '../molecules/form/FormLabelInput';
import FormWrapper from '../molecules/form/FormWrapper';
import AssessmentHeader from '../organisms/headers/AssessmentHeader';
import { useGenerateAssessmentReportMutation } from '../store/api/assessment';
import { useGetSelfAssessmentStatusMutation } from '../store/api/exportFile';
import { IParticipantTypes, IProjectsData } from '../commons/types';
import { projectAssessmentDataHelper } from '../store/api/helpers';

interface IStyledBoxProps extends BoxProps {
  loading?: boolean;
}

const StyledFormWrapper = styled(Box)<IStyledBoxProps>(() => ({
  width: '830px',
  padding: '20px',
  borderRadius: '8px',
}));

interface IAssessmentFormData {
  accountID: string;
  projectID: string;
  report_type: string;
  participantList: Array<string>;
}

export default function Assessment() {
  const methods = useForm<IAssessmentFormData>();

  const [gnerateReport, { isLoading }] = useGenerateAssessmentReportMutation();
  const [getSelfAssessmentStatus, { isLoading: isProjectsLoading }] =
    useGetSelfAssessmentStatusMutation();
  const {
    handleSubmit,
    reset,
    getValues,
    setError,
    clearErrors,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const { toggleErrorSnackbar } = useActions();

  const [clientProjects, setClientProjects] = React.useState<
    Array<IProjectsData>
  >([]);
  const [projectParticipants, setProjectParticipants] = React.useState<
    Array<IParticipantTypes>
  >([]);

  const [formValue, setFormValue] = React.useState<{
    projectId: string;
    participants: Array<string>;
  }>({ projectId: '', participants: [] });

  const onSearch = async () => {
    const accountID: string = getValues('accountID') || '';
    if (accountID) {
      try {
        const response = await getSelfAssessmentStatus({
          accountID,
          assessmentType: 'assessment',
        }).unwrap();
        if (response.results.Projects) {
          const projects = response.results.Projects;
          let projectList = [] as Array<IProjectsData>;
          projects.forEach((project) => {
            if (Array.isArray(project)) {
              const filteredItem = projectAssessmentDataHelper(project);
              projectList = [
                ...projectList,
                ...filteredItem,
              ] as Array<IProjectsData>;
            }
          });
          setClientProjects(projectList);
        } else {
          toggleErrorSnackbar({ message: 'No Projects Found' });
        }
      } catch (e) {
        toggleErrorSnackbar({ message: 'No Projects Found' });
      }
    } else {
      setError('accountID', { message: 'Please provide client id' });
    }
  };

  const onProductDropdownChange = (projectId: string) => {
    setFormValue({ projectId, participants: [] });
    setValue('participantList', []);
    setProjectParticipants(
      clientProjects
        .find((item) => item.projectId === projectId)
        ?.projectResults.reduce((accum, intialValue) => {
          if (
            accum.find(
              (item) => item.participantName === intialValue.participantName
            )
          )
            return accum;
          else return (accum = [...accum, intialValue]);
        }, [] as Array<IParticipantTypes>) || []
    );
  };

  const reportType = watch('report_type');

  React.useEffect(() => {
    if (reportType && reportType.includes('CSV')) {
      setValue('participantList', []);

      setFormValue((value) => ({ ...value, participants: [] }));
    }
  }, [reportType, setValue]);

  const onSubmit = async (formData: IAssessmentFormData) => {
    const payload = {
      accountID: Number(formData.accountID),
      projectID: Number(formValue.projectId),
      ...(formValue.participants.length && {
        user_emails: formValue.participants,
      }),
      reportType: formData.report_type,
      clientId: 'Master',
    };
    const response = await gnerateReport(payload).unwrap();
    if (response.status === 'error' && response.message)
      toggleErrorSnackbar({ message: response.message });
  };

  React.useEffect(() => {
    reset();
  }, [reset]);

  const dropdown = [
    {
      keyValue: '',
      keyName: 'Select',
    },
    {
      keyValue: 'JLR',
      keyName: 'Jaguar Land Rover PDF Report',
    },
    {
      keyValue: 'LDR',
      keyName: 'Leadership Development PDF Report',
    },
    {
      keyValue: 'GSK',
      keyName: 'GSK PDF Report',
    },
    {
      keyValue: 'JLR_CSV',
      keyName: 'Jaguar Land Rover CSV Report',
    },
    {
      keyValue: 'LDR_CSV',
      keyName: 'Leadership Development CSV Report',
    },
    {
      keyValue: 'GSK_CSV',
      keyName: 'GSK CSV Report',
    },
    {
      keyValue: 'HCA',
      keyName: 'HCA Report',
    },
    {
      keyValue: 'scr',
      keyName: 'Scalable Configurable Report',
    },
  ];

  return (
    <Box sx={{ height: 'calc(100vh - 106px - 66px)', overflowY: 'scroll' }}>
      <AssessmentHeader />
      <Container sx={{ marginBottom: '50px' }}>
        <Box sx={{ marginBottom: '20px' }}>
          <Typography variant='h5'>
            A temporary page to generate and export assessment reports
          </Typography>
        </Box>
        <StyledFormWrapper>
          <Box sx={{ marginBottom: '10px' }}>
            <SubTitle text={'Export Leadership Development Reports'} />
          </Box>
          <FormWrapper
            methods={methods}
            id='assessment-report'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <Box sx={{ flexBasis: clientProjects.length ? '100%' : '75%' }}>
                <FormLabelInput
                  name='accountID'
                  rules={{ required: 'Please provide client id' }}
                  label='Client ID'
                  extraLabelInputProps={{
                    disabled: isLoading || !!clientProjects.length || false,
                    type: 'number',
                    onChange: () => {
                      if (errors) clearErrors('accountID');
                    },
                  }}
                />
              </Box>
              {!clientProjects.length && (
                <Button
                  btnType={'PRIMARY'}
                  text={'Search'}
                  onClick={onSearch}
                  sx={{
                    marginTop: '28px',
                    flexBasis: '25%',
                    marginBottom: 'auto',
                  }}
                  form='assessment-report'
                  disabled={!!Object.values(errors).length || isProjectsLoading}
                />
              )}
            </Box>

            {!!clientProjects.length && (
              <>
                <FormLabelDropdown
                  name='projectId'
                  label='Select Project'
                  rules={{ required: 'Please select a Project' }}
                  dropDownItem={clientProjects.map((item) => ({
                    keyName: `${item.projectId} - ${item.projectName}`,
                    keyValue: item.projectId,
                  }))}
                  onChange={(e) =>
                    onProductDropdownChange(e.target.value as string)
                  }
                  defaultValue={''}
                />

                <FormLabelDropdown
                  name='report_type'
                  label='Report Type'
                  rules={{ required: 'Please select a Report Type' }}
                  dropDownItem={dropdown ?? []}
                  defaultValue={''}
                />
              </>
            )}
            {!!projectParticipants.length && (
              <FormLabelDropdown
                name={'participantList'}
                onChange={(e) => {
                  setFormValue((values) => ({
                    ...values,
                    participants: e.target.value as Array<string>,
                  }));
                }}
                label={'Please Select Participants'}
                dropDownItem={projectParticipants.map((item) => ({
                  keyName: item.participantName,
                  keyValue: item.participantEmail,
                }))}
                disabled={reportType.includes('CSV')}
                defaultValue={formValue.participants}
                multiple
              />
            )}
          </FormWrapper>
          <Box
            sx={{
              paddingTop: '20px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {!!clientProjects.length && (
              <Button
                btnType={'PRIMARY'}
                text={'Download Reports'}
                type='submit'
                form='assessment-report'
                disabled={!!Object.values(errors).length || isLoading}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                flexBasis: isProjectsLoading ? '100%' : '50%',
                justifyContent: isProjectsLoading ? 'center' : 'space-between',
              }}
            >
              {(isLoading || isProjectsLoading) && (
                <HDSpinner
                  size='50px'
                  buttonColor={false}
                />
              )}
            </Box>
          </Box>
        </StyledFormWrapper>
      </Container>
    </Box>
  );
}
