import React, { useRef } from 'react';
import { Box, styled } from '@mui/material';
import Button from '../../../../atoms/button/Button';
import Modal from '../../../../atoms/modal/Modal';
import ModalActions from '../../../../atoms/modal/ModalActions';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import AddLeadersTable from '../../../tables/Company/Projects/ProjectDetails/AddLeadersTable';
import { MRT_TableInstance } from 'material-react-table';
import { useParams } from 'react-router-dom';
import { useGetAllLeaderProfilesQuery } from '../../../../store/api/v2/leaderProfile';
import SubTitle from '../../../../atoms/title/SubTitle';
import plusIcon from '../../../../assets/icons/Plus.svg';
import {
  IProjectLeaderType,
  IProjectLeadersData,
} from '../../../../store/api/types/leaderProfile';
import {
  useAddProjectLeaderMutation,
  useUpdateAssignmentStatusMutation,
  useUpdateProjectMutation,
} from '../../../../store/api/projects';
import { useGetAssessmentsStructureByListQuery } from '../../../../store/api/assessmentStructure';
import { assessmentStatusData } from '../../../../commons/types';
import { IAssessmentStructureType } from '../../../../store/api/types/assessmentStructure';
import { IProjectData } from '../../../../store/api/types/projects';
import HDSpinner from '../../../../hd-ui-kit/HDSpinner';

interface IProps {
  leadersData?: Array<IProjectLeadersData>;
  projectData?: IProjectData;
  assessmentStructureIds?: string[];
}
const modalStyle = {
  '& .MuiDialog-paper': {
    padding: '0 25px',
    borderRadius: '8px',
    minWidth: '768px',
    maxWidth: '1200px',
    background:
      'radial-gradient(78.76% 76.42% at 6.02% 9.36%, ' +
      '#E3ECEF 0%, rgba(241, 241, 241) 100%)',
  },
};

const SpinnerWrapper = styled(Box)(() => ({
  '& .la-ball-spin.la-2x': {
    margin: '0 auto',
    top: '40%',
  },
  width: '100%',
  height: '100%',
  minHeight: '200px',
  zIndex: 99,
  textAlign: 'center',
}));

const AddLeadersModal = ({
  leadersData,
  projectData,
  assessmentStructureIds,
}: IProps) => {
  const isOpen = useAppSelector((state) => state.addLeadersModal.isOpen);

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const [leadersSelected, setLeadersSelected] = React.useState<Array<string>>(
    []
  );
  const [clientLeaders, setClientLeadersData] = React.useState<
    Array<IProjectLeaderType>
  >([]);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);

  const [addProjectLeader] = useAddProjectLeaderMutation();
  const [editProjectStatus] = useUpdateProjectMutation();

  const { id, projectId } = useParams();

  const {
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
    openProjectSpinner,
    closeProjectSpinner,
    closeAddLeadersModal,
  } = useActions();

  const [isDisabled, setIsDisabled] = React.useState(false);

  const [updateAssignmentStatus] = useUpdateAssignmentStatusMutation();

  const { data: assessmentData } = useGetAssessmentsStructureByListQuery({
    assessmentIdList: assessmentStructureIds?.toString(),
  });

  const updateAssessmentStatus = async (
    projectsAssessments: Array<{
      assignmentId: string;
      leaderProfileId: string;
      assessments: Array<IAssessmentStructureType>;
    }>
  ) => {
    // Check if Project doesn't have any assessments ,then assessments key is not coming
    Promise.all(
      projectsAssessments.map(async (item) => {
        const payload = {
          projectId: projectId,
          leaderProfileId: item.leaderProfileId || '',
          assignmentId: item.assignmentId || '',
          assessments: item.assessments.map((item) => ({
            assessmentStructureId: item.id,
            assignmentStatus: 'Not Started',
          })),
        };
        return await updateAssignmentStatus(payload as assessmentStatusData);
      })
    )
      .then(() => {
        closeProjectSpinner();
      })
      .catch(() => {
        closeProjectSpinner();
      });
  };

  const { data: { data: profileData } = {} } = useGetAllLeaderProfilesQuery({
    id: id || '',
  });

  React.useEffect(() => {
    if (profileData && leadersData && !clientLeaders.length) {
      const updatedData = profileData
        .filter((o1) => leadersData.every((o2) => o1.id !== o2.leaderProfileId))
        .map((item) => ({
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
        })) as Array<IProjectLeaderType>;

      if (updatedData.length) setClientLeadersData(updatedData);
    }
  }, [profileData, leadersData, clientLeaders.length]);

  const handleClose = async () => {
    closeAddLeadersModal();
  };

  const onSubmit = async () => {
    setIsDisabled(true);
    setShowLoader(true);
    Promise.all(
      leadersSelected.map(
        async (leaderProfileId) =>
          await addProjectLeader({
            projectId: projectId || '',
            leaderProfileId,
          }).unwrap()
      )
    )
      .then((response) => {
        const assignments = response?.map(
          (assignmentData) => assignmentData.data.assignment
        );
        toggleSuccessSnackbar({
          message: 'Participants Added to Project Successfully',
        });
        openProjectSpinner();
        const assessmentsExsists =
          assignments.filter((o) => o?.assessments?.length).length > 0;
        if (!assessmentsExsists) {
          setShowLoader(false);
          handleClose();
          return closeProjectSpinner();
        } else {
          if (assessmentData) {
            const projectsAssessments = assignments
              .map((project) => {
                const projectAssessment = assessmentData.data
                  .filter(({ id }) =>
                    project.assessments.some(
                      (assessment: { assessmentStructureId: string }) =>
                        assessment.assessmentStructureId === id
                    )
                  )
                  .filter((item) => item.category === 'CBI');
                return {
                  assignmentId: project.id,
                  leaderProfileId: project.leaderProfileId,
                  assessments: projectAssessment,
                };
              })
              .filter((item) => !!item.assessments.length);
            if (!projectsAssessments.length) {
              closeProjectSpinner();
              setShowLoader(false);
              handleClose();
              return;
            } else {
              updateAssessmentStatus(projectsAssessments);
              handleClose();
            }
            /* If Leader is added to project and the project has some assessments as CBI,
              then the BE API already assigns them to the assessment,
              in this case simply upate project status to 'In Progress' */
            if (
              projectsAssessments.length &&
              projectData &&
              projectData?.status === 'Draft'
            ) {
              const formStatusData = {
                projectId: String(projectId),
                status: 'In Progress',
              };

              editProjectStatus(formStatusData);
              handleClose();
              setShowLoader(false);
              return;
            }
          }
        }
      })
      .catch(() => {
        setIsDisabled(false);
        toggleErrorSnackbar({ message: 'Error Adding Particpants to Project' });
        handleClose();
        setShowLoader(false);
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      sx={modalStyle}
    >
      <ModalTitle onClose={handleClose}>Add Participant</ModalTitle>
      <SubTitle text={`${clientLeaders.length} total participants`} />
      {showLoader ? (
        <SpinnerWrapper>
          <HDSpinner
            size={'la-2x'}
            buttonColor={false}
          />
        </SpinnerWrapper>
      ) : (
        <Box>
          <ModalBody>
            <AddLeadersTable
              data={clientLeaders}
              tableInstanceRef={tableInstanceRef}
              checkSave={setLeadersSelected}
            />
          </ModalBody>
          <ModalActions>
            <Button
              btnType={'PRIMARY'}
              text={'Add Participants'}
              onClick={onSubmit}
              disabled={!leadersSelected.length || isDisabled}
              endIcon={<img src={plusIcon} />}
            />
            <Button
              btnType={'SECONDARY'}
              text={'Cancel'}
              onClick={handleClose}
            />
          </ModalActions>
        </Box>
      )}
    </Modal>
  );
};

export default AddLeadersModal;
