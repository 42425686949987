import React, { useRef, useState } from 'react';
import Button from '../../../../atoms/button/Button';
import Modal from '../../../../atoms/modal/Modal';
import ModalActions from '../../../../atoms/modal/ModalActions';
import ModalBody from '../../../../atoms/modal/ModalBody';
import ModalTitle from '../../../../atoms/modal/ModalTitle';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import { MRT_TableInstance } from 'material-react-table';
import ReportGenerationTable from '../../../tables/Company/Projects/ProjectDetails/ReportGenerationTable';
import LeadersReportGenerateModal from './LeadersReportGenerateModal';
import {
  IGenerateReportPayload,
  IReportLeaderType,
  IReportsTypeData,
} from '../../../../store/api/types/projects';
import {
  useGenerateReportMutation,
  useGetProjectByIdQuery,
} from '../../../../store/api/projects';
import { useGetClientByIdQuery } from '../../../../store/api/clients';
import { useParams } from 'react-router';
import { useGenerateHeidrickReportMutation } from '../../../../store/api/assessmentReports';
import { useGetMeQuery } from '../../../../store/api/v2/auth';

const modalStyle = {
  '& .MuiDialog-paper': {
    padding: '0 25px',
    borderRadius: '8px',
    minWidth: '768px',
    maxWidth: '1200px',
    background:
      'radial-gradient(78.76% 76.42% at 6.02% 9.36%, ' +
      '#E3ECEF 0%, rgba(241, 241, 241) 100%)',
  },
};

type IProps = {
  resetTableSelections: () => void;
};

const ReportGenerationModal = ({ resetTableSelections }: IProps) => {
  const [rowSelection, setRowSelection] = React.useState<
    Array<IReportsTypeData>
  >([]);

  const params = useParams();

  const [leadersSelected, setLeadersSelected] = React.useState(false);
  const [newTableData, setNewTableData] = useState<any[]>([]);
  const [reportsSelected, setReportsSelected] = React.useState<any[]>([]);
  const [isReportsLoading, setIsReportsLoading] = useState<boolean>(false);
  const [selectedReports, setSelectedReports] = useState<IReportLeaderType>({
    generateReports: [],
    notGenerateReports: [],
  });

  const isOpen = useAppSelector((state) => state.reportGenerationModal.isOpen);
  const reportData = useAppSelector(
    (state) => state.reportGenerationModal.reportData
  );
  const tableData: any[] = useAppSelector(
    (state) => state.reportGenerationModal.generateReportsTableData
  );

  const tazioAssessments = useAppSelector(
    (state) => state.reportGenerationModal.tazio360Assessments
  );

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const {
    closeProjectModal,
    closeLeadersReportGenerateModal,
    openLeadersReportGenerateModal,
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
  } = useActions();

  const [generateReport] = useGenerateReportMutation();
  const [generateHeidrickReport] = useGenerateHeidrickReportMutation();

  const { data: { data = [] } = {} } = useGetClientByIdQuery(
    params.id as string
  );
  const { data: userData } = useGetMeQuery();
  const { data: response } = useGetProjectByIdQuery(params.projectId as string);

  const projectData = response?.data?.at(0);

  const checkSave = (flag: any) => {
    if (flag.length) {
      const temp = flag.map((element: any) => {
        return element.original;
      });
      setReportsSelected(temp);
      setLeadersSelected(true);
    } else {
      setLeadersSelected(false);
    }
  };

  const handleClose = () => {
    closeProjectModal();
    closeLeadersReportGenerateModal();
    resetTableSelections();
  };

  const onSubmit = async () => {
    openLeadersReportGenerateModal(rowSelection);
  };

  const generateReports = async () => {
    setIsReportsLoading(true);

    await Promise.all(
      reportsSelected.map(async (report) => {
        if (report.tableName.toLowerCase().includes('insight')) {
          await Promise.all(
            selectedReports.generateReports.map(async (leader) => {
              try {
                let payload: IGenerateReportPayload = {
                  participantEmail: leader.email,
                  accountID: data?.[0]?.applications?.insights?.accountId ?? '',
                  projectID:
                    projectData?.insightsProject?.sourceProjectId ?? '',
                };

                if (report.originalName === 'insights self') {
                  payload = { ...payload, reportOptions: ['hideInhibEnhance'] };
                }

                const response = await generateReport(payload).unwrap();

                if (response.status == 'Ok') {
                  toggleSuccessSnackbar({
                    message:
                      'The report has been added to the queue for download.',
                  });
                } else if (response.status == 'Error') {
                  toggleErrorSnackbar({
                    message: response.error,
                  });
                } else {
                  if (
                    response.errors &&
                    response.errors[Object.keys(response.errors)[0]].length
                  ) {
                    toggleErrorSnackbar({
                      message:
                        response.errors[Object.keys(response.errors)[0]][0],
                    });
                  }
                }
              } catch (err) {
                toggleErrorSnackbar({ message: 'Error generating reports' });
              }
            })
          );
        } else {
          await Promise.all(
            reportData.map(async (leader) => {
              try {
                const payload = {
                  leaderProfileId: leader?.leaderProfileId,
                  reportType: report?.originalName || '',
                  accountId: params.id || '', // is is the client ID from URL
                  projectId: params.projectId || '', // is is the proejct ID from URL
                  clientId: params.id || '', // sent Client ID for RBAC
                };

                const response = await generateHeidrickReport(payload).unwrap();

                if (response.status === 200) {
                  toggleSuccessSnackbar({
                    message:
                      'The report has been added to the queue for download.',
                  });
                } else if (response.status === 400) {
                  toggleErrorSnackbar({
                    message: response.error,
                  });
                } else {
                  if (
                    response.errors &&
                    response.errors[Object.keys(response.errors)[0]].length
                  ) {
                    toggleErrorSnackbar({
                      message:
                        response.errors[Object.keys(response.errors)[0]][0],
                    });
                  }
                  if (response.errorMessage) {
                    toggleErrorSnackbar({
                      message: response.errorMessage,
                    });
                  }
                }
              } catch (e: any) {
                toggleErrorSnackbar({ message: e.error });
              }
            })
          );
        }
      })
    ).finally(() => {
      handleClose();
    });
  };
  // APS-3719 Client Admin Assessment Status check to generate report logic implemented
  React.useEffect(() => {
    if (
      projectData &&
      userData &&
      reportData.length &&
      reportsSelected.length
    ) {
      const isProjectInsights = !!projectData['insightsProject'];
      const isClientAdmin = userData.data.roles[0].name === 'Client Admins';
      let filteredReports: IReportLeaderType = {
        generateReports: [],
        notGenerateReports: [],
      };

      // Check if SelectedReports containse insights self
      const isInsightSelfReport = reportsSelected.some((item) =>
        item?.originalName?.includes('insights self')
      );

      // Check if Client Admin, Client Admin Only can generate reports if assessment status is complete and 360 assessments are meeting threshold
      if (isClientAdmin && isInsightSelfReport) {
        // Check for 360 Assessments if Project have 360 assessments
        if (isProjectInsights && tazioAssessments.length) {
          filteredReports = reportData.reduce(
            (acc, initValue) => {
              // Check if assessment have Complete status
              if (initValue.totalAssessments === initValue.complete) {
                // Check participant have 360 Assessments
                const participant = tazioAssessments.find(
                  (item) => item.participantName === initValue.leaderEmail
                );
                // If Participant have 360 assessment Check it's status if assessment is complete or not
                if (participant) {
                  // Check 360 Assessment Status
                  if (participant.status)
                    acc.generateReports.push({
                      name: initValue.leader,
                      email: initValue.leaderEmail,
                    });
                  else acc.notGenerateReports.push(initValue.leader);
                } else
                  acc.generateReports.push({
                    name: initValue.leader,
                    email: initValue.leaderEmail,
                  });
              } else {
                acc.notGenerateReports.push(initValue.leader);
              }
              return acc;
            },
            { generateReports: [], notGenerateReports: [] }
          );
        } else {
          // check for assessment status for non 360 assessments
          filteredReports = reportData.reduce(
            (acc, initValue) => {
              if (initValue.totalAssessments === initValue.complete) {
                acc.generateReports.push({
                  name: initValue.leader,
                  email: initValue.leaderEmail,
                });
              } else {
                acc.notGenerateReports.push(initValue.leader);
              }
              return acc;
            },
            { generateReports: [], notGenerateReports: [] }
          );
        }
      } else {
        filteredReports.generateReports = reportData.map((item) => ({
          name: item?.leader || '',
          email: item?.leaderEmail,
        }));
      }
      setSelectedReports(filteredReports);
    }
  }, [projectData, userData, reportData, tazioAssessments, reportsSelected]);

  React.useEffect(() => {
    if (projectData && !projectData['insightsProject']) {
      const filteredTableData: any[] = tableData.filter(
        (report) => report.originalName !== 'insights self'
      );
      setNewTableData(filteredTableData);
    } else {
      // Duplicate tableData values removed
      const filteredTableData = tableData.reduce(
        (accumulator, initValue, key) => {
          if (!key) accumulator.push(initValue);
          else {
            if (
              !accumulator.find(
                (item: { tableName: string; originalName: string }) =>
                  item.tableName === initValue?.tableName
              )
            )
              accumulator.push(initValue);
          }
          return accumulator;
        },
        []
      );
      setNewTableData(filteredTableData);
    }
  }, [tableData, projectData]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      sx={modalStyle}
    >
      <ModalTitle onClose={handleClose}>
        Generate Reports for {reportData.length} Leaders
      </ModalTitle>
      <ModalBody>
        <ReportGenerationTable
          data={newTableData}
          checkSave={checkSave}
          tableInstanceRef={tableInstanceRef}
          setRowSelection={setRowSelection}
        />
        <LeadersReportGenerateModal
          selectedReports={selectedReports}
          generateReports={generateReports}
          isLoading={isReportsLoading}
        />
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Generate Reports'}
          onClick={onSubmit}
          disabled={!rowSelection.length}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
};

export default ReportGenerationModal;
