import React from 'react';
import { Box, styled } from '@mui/material';
import { useLocation, useParams } from 'react-router';
import ClientProjectDetailsHeader from '../../../../organisms/headers/Company/Projects/ClientProjectDetailsHeader';
import TabMenu from '../../../../atoms/tab/TabMenu';
import TabMenuItem from '../../../../atoms/tab/TabMenuItem';
import HpContainer from '../../../../atoms/container/HpContainer';
import { ProjectAssessments } from './ProjectAssessments';
import ProjectLeaders from './ProjectLeaders';
import { LeaderReports } from './LeaderReports';
import { useGetProjectByIdQuery } from '../../../../store/api/projects';
import { useGetAllLeaderProfilesQuery } from '../../../../store/api/v2/leaderProfile';
import { useGetRoleProfilesQuery } from '../../../../store/api/leadership';
import { useLazyGetAssessmentsStructureByListQuery } from '../../../../store/api/assessmentStructure';
import {
  Assessments,
  AssessmentsTable,
  AssignmentDetails,
  IAssignmentAssessmentType,
  IProjectData,
} from '../../../../store/api/types/projects';
import { useGetClientByIdQuery } from '../../../../store/api/clients';
import { mapAssessmentToReport } from '../../../../utils/helpers';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import { restrictedHandler } from '../../../../rbac/Restricted';
import { useGetMeQuery } from '../../../../store/api/v2/auth';
import { set360TableData } from '../../../../store/api/helpers';
import { IAssessmentData } from '../../../../store/api/types/assessmentStatusDashboard';
import { statusDropdown } from '../../../../commons/projectPurposeDropdownData';
import { IParticipantsReports } from '../../../../commons/types';
import SummaryMetrics, {
  SummaryMetricsData,
} from '../../../../atoms/header/SummaryMetrics';
import {
  IAssessmentStructureType,
  IAssessmentsStructureResponse,
} from '../../../../store/api/types/assessmentStructure';
import { IProjectLeadersData } from '../../../../store/api/types/leaderProfile';
import AssignmentsTab from './AssignmentsTab';

type ITazioAssessmentsType = {
  assessmentsData: IAssessmentData[];
};

type IInsightsType = {
  accountId: string;
  addedTs: string;
  apiKey: string;
  bulkImportAssessmentsEnabled: boolean;
  isEnabled: boolean;
  lastUpdatedTs: string;
  projectId: string;
  url: string;
};

type IParticipantTableData = {
  id: string;
  leader: string;
  leaderEmail: string;
  staged: number;
  assigned: number;
  notStarted: number;
  inProgress: number;
  complete: number;
  leaderProfileId: string;
  assignmentId: string;
  assessments: IAssignmentAssessmentType[];
  assessmentId: string;
  isManualAssigned: boolean;
  status: string;
  totalAssessments: number;
}[];

type IAggregateType = {
  staged: number;
  inProgress: number;
  assigned: number;
  completed: number;
  notStarted: number;
  incomplete: number;
  totalAssessments: number;
};

const StyledTabContentWrapper = styled(Box)(() => ({
  paddingTop: '40px',
}));

const ClientProjectDetails = () => {
  const params = useParams();
  const { pathname } = useLocation();

  const {
    addAvailableReports,
    toggleErrorSnackbar,
    setReportTazio360Assessment,
  } = useActions();

  const [tabValue, setTabValue] = React.useState(0);
  const [tableData, setTableData] = React.useState<AssessmentsTable[]>([]);
  const [assessmentData, setAssessmentData] =
    React.useState<IAssessmentsStructureResponse>();
  const [tableFilters, setTableFilters] = React.useState<{
    assessment: string;
    participant: string;
  }>({ assessment: '', participant: '' });

  const [reportAttachmentIds, setReportAttachmentIds] =
    React.useState<IParticipantsReports>([]);
  const [metricsData, setMetricsData] = React.useState<SummaryMetricsData[]>(
    []
  );

  const { data: { data: clientData } = {} } = useGetClientByIdQuery(
    params.id as string
  );

  const { data: userData } = useGetMeQuery();
  const [getAssessmentStructures, { isLoading: isStructLoading }] =
    useLazyGetAssessmentsStructureByListQuery();

  const { data: response } = useGetProjectByIdQuery(params.projectId as string);
  const projectData = response?.data;
  const data = response?.data?.at(0);

  const [leaderTableData, setLeaderTableData] = React.useState<
    Array<IProjectLeadersData>
  >([]);

  const [tazioAssessments, setTazioAssessments] =
    React.useState<ITazioAssessmentsType>({
      assessmentsData: [],
    });
  const [cbiAssessments, setCbiAssessments] = React.useState<
    Array<IAssessmentData>
  >([]);

  const { data: { data: profileData } = {} } = useGetAllLeaderProfilesQuery({
    id: params.id || '',
  });

  const isLoading = useAppSelector((state) => state.projectSpinner.isLoading);

  // Get 360 Assessments Data and process the data to show in Assessment Table
  const get360AssessmentData = React.useCallback(
    async (insights: IInsightsType, sourceProjectId: number) => {
      try {
        // Get assessmentStructureIds added to project , to  fetch its data in one call
        let assessmentStructureIds: string[] = [];
        if (data?.assessmentStructures) {
          assessmentStructureIds =
            data?.assessmentStructures.map(
              (item: { assessmentStructureId: any }) =>
                item?.assessmentStructureId
            ) || [];
        }

        const { data: assessmentResponseData } = await getAssessmentStructures({
          assessmentIdList: assessmentStructureIds?.toString(),
        });

        const assessmentsList = assessmentResponseData?.data;

        if (insights?.accountId && sourceProjectId) {
          // filter out the leaders with 360 assessments
          const leaders = data?.assignments?.map((leader) => {
            const all360Assessments = leader?.assessments.map((obj) => {
              const foundObject = assessmentsList?.find(
                (assessment) => assessment.id === obj.assessmentStructureId
              );
              return {
                ...obj,
                leaderName:
                  leader.leaderFirstName + ' ' + leader.leaderLastName,
                leaderEmail: leader.leaderEmail,
                project: data.name,
                startDate: data.startDate,
                closeDate: data.closeDate,
                assessment: foundObject ? foundObject.title : '',
              };
            });
            return all360Assessments;
          });

          // all leaders containing their 360 assessments refined
          const filter360Leaders = leaders?.filter(
            (leader) => leader.length > 0
          );

          const mergedArray = filter360Leaders?.reduce(
            (acc, curr) => acc.concat(curr),
            []
          );

          const tableData360 = set360TableData(mergedArray);

          setTazioAssessments((values) => ({
            ...values,
            assessmentsData: [...values.assessmentsData, ...tableData360],
          }));
        }
      } catch (e) {
        toggleErrorSnackbar({ message: 'Unable to Get 360 Assessments' });
      }
    },
    [data]
  );

  const getAllAssignments = React.useCallback(
    (data: IProjectData, assessmentData: IAssessmentStructureType[]) => {
      if (data && data?.assignments && data.assignments?.length) {
        const assignmentsData = data.assignments.reduce((acc, leader) => {
          if (leader?.assessments?.length) {
            const tempData: IAssessmentData[] = leader?.assessments.map(
              (assessment) => {
                const assessmentType = assessmentData.find(
                  (item) => item.id === assessment.assessmentStructureId
                );
                return {
                  participant:
                    leader.leaderFirstName + ' ' + leader.leaderLastName,
                  email: leader?.leaderEmail || '-',
                  assessment: assessmentType
                    ? assessmentType?.title || '-'
                    : '-',
                  project: data.name || '-',
                  projectStartDate: data.startDate || '-',
                  projectEndDate: data?.closeDate || '-',
                  type: assessmentType ? assessmentType?.category || '-' : '-',
                  status: assessment.assignmentStatus || '-',
                  id: leader.id || '-',
                  raters: [],
                  completedDate: '-',
                  completedPercentage: '-',
                  lastReminderSent: '-',
                  reminderCount: '-',
                };
              }
            );

            return [...acc, ...tempData];
          }
          return acc;
        }, [] as IAssessmentData[]);
        if (assignmentsData.length)
          setTazioAssessments((values) => ({
            ...values,
            assessmentsData: assignmentsData.filter(
              (item) => item.type === 'CBI'
            ),
          }));
      }
    },
    []
  );

  React.useEffect(() => {
    if (clientData?.length && data && assessmentData?.data?.length) {
      const insights = clientData[0]?.applications?.insights as IInsightsType;
      const sourceProjectId = data?.insightsProject?.sourceProjectId || '';
      if (
        insights?.isEnabled &&
        sourceProjectId.length &&
        !tazioAssessments.assessmentsData.length
      ) {
        get360AssessmentData(insights, Number(sourceProjectId));
      }
      getAllAssignments(data, assessmentData.data);
    }
  }, [clientData, data, assessmentData]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { data: { data: roleData } = {} } = useGetRoleProfilesQuery(
    pathname.includes('company') ? (params.id as string) : 'Master'
  );

  // Function to get Leaders asssessments status for row
  const getAssessmentStatusCount = (leadersAssessmentsData: Assessments[]) => {
    let staged = 0;
    let inprogress = 0;
    let assigned = 0;
    let completed = 0;
    for (let i = 0; i < leadersAssessmentsData.length; i++) {
      if (leadersAssessmentsData[i].assignmentStatus === 'Not Invited') {
        staged++;
      }
      if (leadersAssessmentsData[i].assignmentStatus === 'In Progress') {
        inprogress++;
      }
      if (leadersAssessmentsData[i].assignmentStatus === 'Not Started') {
        assigned++;
      }
      if (leadersAssessmentsData[i].assignmentStatus === 'Completed') {
        completed++;
      }
    }
    const aggregateData = {
      staged: staged,
      inProgress: inprogress,
      assigned: assigned,
      completed: completed,
      notStarted: staged + assigned,
      incomplete: inprogress,
      totalAssessments: leadersAssessmentsData.length,
    };
    return aggregateData;
  };

  // get leader status
  const getLeaderStatus = (
    leadersAssessmentsData: Assessments[],
    aggregateData: IAggregateType
  ) => {
    if (aggregateData.inProgress) {
      return 'In Progress';
    } else if (leadersAssessmentsData.length === 0) {
      return 'Not Invited';
    } else if (leadersAssessmentsData.length === aggregateData.completed) {
      return 'Completed';
    } else if (leadersAssessmentsData.length === aggregateData.assigned) {
      return 'Not Started';
    } else if (leadersAssessmentsData.length === aggregateData.staged) {
      return 'Not Invited';
    } else if (
      aggregateData.staged &&
      aggregateData.notStarted &&
      !aggregateData.completed &&
      !aggregateData.inProgress
    ) {
      return 'Not Started';
    } else {
      return 'In Progress';
    }
  };
  // function to fetch all report attachment Ids
  const getReportData = (data: any) => {
    const reportIdsList: any = [];
    const assignmentLength = data?.assignments?.length || 0;

    for (let i = 0; i < assignmentLength; i++) {
      if (data?.assignments[i]?.reportAttachments) {
        data?.assignments[i]?.reportAttachments.forEach((reportId: any) => {
          const temp = {
            leader:
              data?.assignments[i].leaderFirstName +
              ' ' +
              data?.assignments[i].leaderLastName,
            leaderEmail: data?.assignments[i].leaderEmail,
            leaderProfileId: data?.assignments[i].leaderProfileId,
            reportId: reportId,
            assessmentsData: data?.assignments[i].assessments,
          };

          reportIdsList.push(temp);
        });
      }
    }
    setReportAttachmentIds(reportIdsList);
  };

  // function with logic to create leader table data
  const createTableData = (projectLeadersData: AssignmentDetails[]) => {
    // APS-3719 360 Assessment Status Filter
    const filtered360Assessments = projectLeadersData
      .filter((item) => {
        if (item?.assessments?.find((item) => item?.appraisalStatus))
          return true;
        else false;
      })
      .map((item) => {
        const tazioAssessments = item.assessments.filter(
          (assessment) => assessment?.appraisalStatus
        );
        const status = tazioAssessments.every(
          (assessmentStatus) => assessmentStatus.appraisalStatus === 'Completed'
        );
        return { participantName: item.leaderEmail, status };
      });
    setReportTazio360Assessment(filtered360Assessments);

    const tempTableDataRd: IProjectLeadersData[] = projectLeadersData.map(
      (item) => {
        const leaderAssessmentsStatusCount = getAssessmentStatusCount(
          item?.assessments || []
        );
        return {
          id: item.id,
          leader: `${item.leaderFirstName} ${item.leaderLastName}`,
          leaderEmail: item.leaderEmail,
          staged: leaderAssessmentsStatusCount.staged,
          assigned: leaderAssessmentsStatusCount.assigned,
          inProgress: leaderAssessmentsStatusCount.inProgress,
          complete: leaderAssessmentsStatusCount.completed,
          notStarted: leaderAssessmentsStatusCount.notStarted,
          leaderProfileId: item.leaderProfileId,
          assessments: item.assessments,
          assessmentId: item.id,
          isManualAssigned: item.isManualAssigned,
          assignmentId: item.id,
          status: getLeaderStatus(
            item?.assessments || [],
            leaderAssessmentsStatusCount
          ),
          totalAssessments: leaderAssessmentsStatusCount.totalAssessments,
          report: item?.reportAttachments ? 'Yes' : 'No',
        };
      }
    );
    return tempTableDataRd;
  };

  const getAllAssessments = async () => {
    if (data !== undefined && data?.assessmentStructures?.length > 0) {
      const temp: any[] = [];

      // Get assessmentStructureIds added to project , to  fetch its data in one call
      let assessmentStructureIds: string[] = [];
      if (data?.assessmentStructures) {
        assessmentStructureIds =
          data?.assessmentStructures.map(
            (item: { assessmentStructureId: any }) =>
              item?.assessmentStructureId
          ) || [];
      }

      const { data: assessmentResponseData } = await getAssessmentStructures({
        assessmentIdList: assessmentStructureIds?.toString(),
      });

      if (assessmentResponseData) {
        // Save this assessments response to a state to use it in Assignments Tab
        setAssessmentData(assessmentResponseData);
        assessmentResponseData.data.map((assessmentResponse) => {
          let staged = 0;
          let assigned = 0;
          let inProgress = 0;
          let completed = 0;

          data?.assignments?.forEach((leader) => {
            leader?.assessments?.forEach((assessment) => {
              if (assessment.assessmentStructureId === assessmentResponse?.id) {
                if (assessment.assignmentStatus === 'Not Invited') staged++;
                else if (assessment.assignmentStatus === 'Not Started')
                  assigned++;
                else if (assessment.assignmentStatus === 'In Progress')
                  inProgress++;
                else completed++;
              }
            });
          });
          temp.push({
            id: assessmentResponse?.id,
            assessment: assessmentResponse?.title,
            skillCategory: assessmentResponse?.category,
            type: assessmentResponse?.category,
            staged: staged,
            assigned: assigned,
            inProgress: inProgress,
            completed: completed,
          });
          return true;
        });
      }
      if (temp) {
        setTableData(temp);
      }
    }
  };

  // Function to get All Assignments Data

  React.useEffect(() => {
    setTableData([]);
    getAllAssessments();
    getReportData(data);
  }, [data]);

  React.useEffect(() => {
    // logic for creating list of available reports to generate
    const listOfTypes = tableData.map((el) => el.type?.toLowerCase() || '');
    const clientName = clientData?.at(0)?.name.toLowerCase();

    const reportsList = mapAssessmentToReport(
      listOfTypes as [],
      clientName as string
    );
    addAvailableReports(reportsList);
  }, [tableData]);

  React.useEffect(() => {
    if (projectData && projectData[0]?.assignments?.length) {
      const tableData = createTableData(projectData[0].assignments);
      setLeaderTableData(tableData);
    }
  }, [roleData, projectData, profileData]);

  React.useEffect(() => {
    const totalAssignments = [
      ...cbiAssessments,
      ...tazioAssessments.assessmentsData,
    ];
    const completedAssignments = totalAssignments?.filter(
      (assignment) => assignment.status === 'Completed'
    );

    const metricsArray = [
      {
        itemName: 'Total number of participants',
        itemQty: leaderTableData?.length || 0,
      },
      {
        itemName: 'Total number of assessments provisioned',
        itemQty: totalAssignments?.length || 0,
      },
      {
        itemName: 'Total number of assessments completed',
        itemQty: completedAssignments?.length || 0,
      },
    ];

    setMetricsData(metricsArray);
  }, [tazioAssessments, leaderTableData, cbiAssessments]);

  const assignmentData = [
    ...cbiAssessments,
    ...tazioAssessments.assessmentsData,
  ];

  return (
    <Box>
      <ClientProjectDetailsHeader id={params.projectId as string} />
      <HpContainer>
        <SummaryMetrics data={metricsData} />
      </HpContainer>
      <HpContainer
        sx={{
          marginTop: '20px',
        }}
      >
        <TabMenu
          value={tabValue}
          onChange={handleTabChange}
        >
          <TabMenuItem
            index={0}
            label={`Assessments(${tableData.length})`}
            disabled={isLoading}
          />
          <TabMenuItem
            index={1}
            label={`Participants(${leaderTableData.length})`}
            disabled={isLoading}
          />
          {restrictedHandler(
            'project.project_report.read',
            userData?.permissionsArr
          ) && (
            <TabMenuItem
              index={3}
              label={`Participant Reports(${reportAttachmentIds.length})`}
              disabled={isLoading}
            />
          )}
          <TabMenuItem
            index={3}
            label={`Assignments(${assignmentData.length})`}
            disabled={isLoading}
          />
        </TabMenu>
        <StyledTabContentWrapper>
          <ProjectAssessments
            index={0}
            value={tabValue}
            data={tableData}
            projectLeaderData={leaderTableData}
            isLoading={isStructLoading}
            setTabValue={setTabValue}
            setTableFilters={setTableFilters}
          />
          <ProjectLeaders
            index={1}
            value={tabValue}
            data={leaderTableData}
            projectData={data}
            setTabValue={setTabValue}
            setAssignmentTableFilter={setTableFilters}
          />
          {restrictedHandler(
            'project.project_report.read',
            userData?.permissionsArr
          ) && (
            <LeaderReports
              index={2}
              value={tabValue}
              reportAttachmentsList={reportAttachmentIds}
            />
          )}
          <AssignmentsTab
            index={3}
            value={tabValue}
            projectAssignment={true}
            statusDropdown={statusDropdown}
            assessmentData={assignmentData}
            tableFilters={tableFilters}
          />
        </StyledTabContentWrapper>
      </HpContainer>
    </Box>
  );
};

export default ClientProjectDetails;
