import React from 'react';
import { Box, IconButton, Menu, MenuItem, styled } from '@mui/material';
import Title from '../../../../atoms/title/Title';
import SubTitle from '../../../../atoms/title/SubTitle';
import Button from '../../../../atoms/button/Button';
import HDBreadCrumb from '../../../../hd-ui-kit/HDBreadcrumb';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DetailsHeaderWrapper from '../../../../atoms/header/DetailsHeaderWrapper';
import { useActions } from '../../../../hooks/actions';
import CompanyImpactGroupModal from '../../../modals/Company/CompanyImpactGroupModal';
import ImportIcon from '../../../../assets/icons/Import.svg';
import {
  IImpactGroupData,
  IRoleSkillData,
} from '../../../../store/api/types/leadership';
import { useDeleteLeaderImpactGroupMutation } from '../../../../store/api/leadership';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetClientByIdQuery } from '../../../../store/api/clients';
import {
  useCreateAssessmentMutation,
  useGetImpactAssessmentByAssessmentStructureMutation,
  useGetImpactAssessmentJSONMutation,
  useGetImpactAssessmentMutation,
} from '../../../../store/api/exportFile';
import {
  useEditAssessmentStructureMutation,
  useGetAssessmentsStructureQuery,
  useLazyGetAssessmentsStructureQuery,
} from '../../../../store/api/assessmentStructure';
import _ from 'lodash';
import HDSpinner from '../../../../hd-ui-kit/HDSpinner';
import { Restricted } from '../../../../rbac/Restricted';

interface IProps {
  impactGroup: IImpactGroupData;
  statementData: Array<IRoleSkillData> | [];
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const BreadCrumbWrapper = styled(Box)(() => ({
  marginTop: '20px',
  marginBottom: '40px',
}));

const StyledButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}));

const StyledTopSectionWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '15px',
}));

const StyledWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  paddingLeft: '50px',
  paddingRight: '50px',
  maxWidth: '1910px',
}));

const ButtonIcon = styled('img')(() => ({
  width: '16px',
  height: '16px',
}));

const ImpactGroupDetailHeader = ({ impactGroup, statementData }: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [toggleButton, setToggleButton] = React.useState(false);
  const [disableDownloadBtn, setDisableDownloadBtn] =
    React.useState<boolean>(false);
  const [disableGenerateBtn, setDisableGenerateBtn] =
    React.useState<boolean>(false);
  const [disableReleaseBtn, setDisableReleaseBtn] =
    React.useState<boolean>(false);

  const { editCompanyImpactGroupModal, openAddRoleToImpactGroupModal } =
    useActions();

  const [generateImpactAssessmentHandler, { isLoading }] =
    useGetImpactAssessmentMutation();
  const [downloadJSON, { isLoading: inProgress }] =
    useGetImpactAssessmentJSONMutation();
  const [getImpactAssessment] =
    useGetImpactAssessmentByAssessmentStructureMutation();
  const [createAssessment] = useCreateAssessmentMutation();
  const [editAssessment] = useEditAssessmentStructureMutation();

  const [deleteImpactGroup] = useDeleteLeaderImpactGroupMutation();

  const [getNewAssessment, { isLoading: fetching }] =
    useLazyGetAssessmentsStructureQuery();

  const { groupId, id: clientId } = useParams();
  const navigate = useNavigate();

  // Get assessments data to check if assessment for impact group already created
  const { data: { data: assessmentData } = {} } =
    useGetAssessmentsStructureQuery({
      clientId: clientId,
      limit: undefined,
      offset: undefined,
    });

  const { data: { data: clientData } = {} } = useGetClientByIdQuery(
    clientId as string,
    {
      skip: false,
    }
  );

  const { toggleSuccessSnackbar, toggleErrorSnackbar } = useActions();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditImpactGroup = () => {
    setAnchorEl(null);
    editCompanyImpactGroupModal(impactGroup);
  };

  const handleDeleteImpactGroup = () => {
    setAnchorEl(null);
    deleteImpactGroup(groupId || '')
      .unwrap()
      .then(() => {
        toggleSuccessSnackbar({ message: 'Impact Group deleted successfully' });
        navigate(-1);
      })
      .catch((e) => {
        const error = e as { status: number; data: { detail: string } };
        toggleErrorSnackbar({ message: error.data.detail });
      });
  };

  const handleAddRole = () => {
    openAddRoleToImpactGroupModal();
  };

  const handleDownloadJSON = () => {
    setDisableDownloadBtn(true);
    getNewAssessment({
      clientId: clientId || '',
      limit: undefined,
      offset: undefined,
    })
      .unwrap()
      .then((response) => {
        const data = checkImpactGroupAssessmentexists(response.data);
        if (data) {
          const paramters = {
            id: data?.id || '',
            impactGroupName: 'Impact Group - ' + impactGroup.name!,
          };
          downloadJSON(paramters)
            .unwrap()
            .then(() => {
              toggleSuccessSnackbar({
                message: 'Impact Assessment Downloaded Successfully',
              });
              setDisableDownloadBtn(false);
            });
        }
      });
  };

  const handleReleaseImpactAssessment = () => {
    if (clientData?.length) {
      const insights = clientData[0]?.applications?.insights;
      if (!insights?.accountId) {
        toggleErrorSnackbar({
          message: 'Insights Account ID is missing or invalid',
        });
      }
      if (insights?.accountId) {
        setDisableReleaseBtn(true);
        getNewAssessment({
          clientId: clientId || '',
          limit: undefined,
          offset: undefined,
        })
          .unwrap()
          .then((response) => {
            const data = checkImpactGroupAssessmentexists(response.data);
            if (data) {
              const paramters = {
                id: data?.id || '',
              };
              getImpactAssessment(paramters)
                .unwrap()
                .then((impactRes) => {
                  createAssessment({
                    accountID: Number(insights?.accountId),
                    assessmentType: 'ImpactQuestionBank',
                    assessmentDefinition: impactRes.data,
                  })
                    .then((assessmentResp: any) => {
                      const payloadObj = { ...impactRes.data };
                      delete payloadObj['version'];
                      createAssessment({
                        accountID: Number(insights?.accountId),
                        assessmentType: 'Impact',
                        assessmentDefinition: payloadObj,
                      })
                        .then((assessmentRes: any) => {
                          if (
                            !assessmentRes?.data?.assessmentCreated
                              ?.assessmentID
                          ) {
                            throw new Error(
                              'Error in releasing impact assessment'
                            );
                          }
                          editAssessment({
                            id: data?.id,
                            sourceAssessmentId:
                              assessmentRes?.data?.assessmentCreated
                                ?.assessmentID,
                          })
                            .then(() => {
                              toggleSuccessSnackbar({
                                message:
                                  'Impact Assessment Released Successfully',
                              });
                              setDisableReleaseBtn(false);
                            })
                            .catch((err) => {
                              console.log('err1 ', err);
                              setDisableReleaseBtn(false);
                              toggleErrorSnackbar({ message: err.message });
                            });
                        })
                        .catch((err) => {
                          setDisableReleaseBtn(false);
                          toggleErrorSnackbar({ message: err.message });
                        });
                    })
                    .catch((err) => {
                      setDisableReleaseBtn(false);
                      toggleErrorSnackbar({ message: err.message });
                    });
                })
                .catch((err) => {
                  setDisableReleaseBtn(false);
                  toggleErrorSnackbar({ message: err.message });
                });
            }
          })
          .catch((err) => {
            setDisableReleaseBtn(false);
            toggleErrorSnackbar({ message: err.message });
          });
      }
    }
  };

  const generateImpactAssessment = () => {
    setDisableGenerateBtn(true);
    const uniqueStatements = _.uniqBy(statementData, 'statementCode');
    const statementCodeList = uniqueStatements.map((item) => {
      return item.statementCode;
    });

    const payload = {
      clientId: clientId!,
      assessmentName: 'Impact Group - ' + impactGroup.name!,
      statementCodeList: statementCodeList.toString(),
    };

    generateImpactAssessmentHandler(payload)
      .unwrap()
      .then((response) => {
        const paramters = {
          id: response.data.assessmentStructureId || '',
          impactGroupName: 'Impact Group - ' + impactGroup.name!,
        };
        downloadJSON(paramters)
          .unwrap()
          .then((response) => {
            toggleSuccessSnackbar({
              message: 'Impact Assessment Generated Successfully',
            });
            setToggleButton(true);
          });
      })
      .catch((e: any) => {
        toggleErrorSnackbar({ message: e.data.detail });
      });
  };

  // function to check if impact group assessments is created already
  const checkImpactGroupAssessmentexists = (assessmentData: any) => {
    const exists =
      (assessmentData &&
        assessmentData.find(
          (impactgroup: { title: string }) =>
            impactgroup.title === 'Impact Group - ' + impactGroup.name
        )) ||
      null;
    return exists;
  };

  React.useEffect(() => {
    const toggleGenerateDownloadButton = () => {
      const data = checkImpactGroupAssessmentexists(assessmentData);
      _.isEmpty(data) ? setToggleButton(false) : setToggleButton(true);
    };

    if (assessmentData) {
      toggleGenerateDownloadButton();
    }
  }, [assessmentData]);

  return (
    <>
      <DetailsHeaderWrapper>
        <Box sx={{ width: '100%' }}>
          <StyledTopSectionWrapper>
            <BreadCrumbWrapper>
              <HDBreadCrumb
                routes={[
                  { text: 'Impact Groups' },
                  { text: `${impactGroup.name} (ID ${impactGroup.id})` },
                ]}
              />
            </BreadCrumbWrapper>
          </StyledTopSectionWrapper>
          <StyledTitlesWrapper>
            <Title
              text={impactGroup.name || ''}
              sx={{ maxWidth: '740px' }}
            />
            <StyledButtonWrapper>
              {toggleButton === true &&
                (disableReleaseBtn ? (
                  <Restricted allowedTo='client.impact_groups.release_impact_assessment'>
                    <Button
                      btnType='SECONDARY'
                      text={'Release Impact Assessment'}
                      disabled={disableReleaseBtn}
                      onClick={handleReleaseImpactAssessment}
                      endIcon={
                        <HDSpinner
                          size=''
                          buttonColor={false}
                        />
                      }
                    />
                  </Restricted>
                ) : (
                  <Restricted allowedTo='client.impact_groups.release_impact_assessment'>
                    <Button
                      btnType='SECONDARY'
                      text={'Release Impact Assessment'}
                      onClick={handleReleaseImpactAssessment}
                    />
                  </Restricted>
                ))}
              {toggleButton === true &&
                (disableDownloadBtn ? (
                  <Button
                    btnType='SECONDARY'
                    text={'Download Impact Assessment'}
                    disabled={disableDownloadBtn}
                    onClick={handleDownloadJSON}
                    startIcon={
                      <ButtonIcon
                        src={ImportIcon}
                        alt='Import CSV'
                      />
                    }
                    endIcon={
                      <HDSpinner
                        size=''
                        buttonColor={false}
                      />
                    }
                  />
                ) : (
                  <Button
                    btnType='SECONDARY'
                    text={'Download Impact Assessment'}
                    onClick={handleDownloadJSON}
                    startIcon={
                      <ButtonIcon
                        src={ImportIcon}
                        alt='Import CSV'
                      />
                    }
                  />
                ))}
              {toggleButton === false &&
                (disableGenerateBtn ? (
                  <Button
                    btnType='SECONDARY'
                    text={'Generate Impact Assessment'}
                    disabled={disableGenerateBtn}
                    onClick={generateImpactAssessment}
                    startIcon={
                      <ButtonIcon
                        src={ImportIcon}
                        alt='Import CSV'
                      />
                    }
                    endIcon={
                      <HDSpinner
                        size=''
                        buttonColor={false}
                      />
                    }
                  />
                ) : (
                  <Button
                    btnType='SECONDARY'
                    text={'Generate Impact Assessment'}
                    onClick={generateImpactAssessment}
                    startIcon={
                      <ButtonIcon
                        src={ImportIcon}
                        alt='Import CSV'
                      />
                    }
                  />
                ))}

              <Button
                btnType='PRIMARY'
                text={'Add Roles'}
                onClick={handleAddRole}
              />
              <IconButton onClick={handleOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleEditImpactGroup()}>
                  Edit
                </MenuItem>
                <MenuItem onClick={() => handleDeleteImpactGroup()}>
                  Delete
                </MenuItem>
              </Menu>
            </StyledButtonWrapper>
          </StyledTitlesWrapper>
        </Box>
      </DetailsHeaderWrapper>
      <StyledWrapper>
        <SubTitle text={impactGroup.description || ''} />
      </StyledWrapper>
      <CompanyImpactGroupModal />
    </>
  );
};

export default ImpactGroupDetailHeader;
