import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  IAddAssessmentPayload,
  IAssessmentsStructureResponse,
} from './types/assessmentStructure';
import { IExcelResponse } from './types/leadership';
import {
  downloadFile,
  saveFileNameAsCSV,
  serializeObjectToParams,
} from './helpers';

export const assessmentStructureApi = createApi({
  reducerPath: 'assessmentstructure/api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_V2_API_KEY}assessmentstructure/api/v2/`,
    prepareHeaders(headers) {
      const token = JSON.parse(localStorage.getItem('okta-token-storage') ?? '')
        .accessToken.accessToken;
      const authZToken = localStorage.getItem('authzToken') || '';
      if (authZToken.length) headers.set('X-Auth-Z', authZToken);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['assessmentstructure', 'templates'],
  endpoints: (builder) => ({
    getAssessmentStructureById: builder.query<
      any,
      { assessmentStructureId: string | undefined }
    >({
      query: ({ assessmentStructureId }) =>
        `assessments/${assessmentStructureId}`,
    }),
    getAssessmentsStructure: builder.query<
      IAssessmentsStructureResponse,
      { clientId?: string; limit?: number; offset?: number; status?: string }
    >({
      query: (params) => `assessments?${serializeObjectToParams(params)}`,
      providesTags: ['assessmentstructure'],
    }),
    addAssessmentStructure: builder.mutation<any, IAddAssessmentPayload>({
      query: (payload) => ({
        url: 'assessments',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['assessmentstructure'],
    }),
    editAssessmentStructure: builder.mutation<any, IAddAssessmentPayload>({
      query: ({ id, ...payload }) => ({
        url: `assessments/${id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['assessmentstructure'],
    }),
    getAssessmentsStructureByList: builder.query<
      IAssessmentsStructureResponse,
      { assessmentIdList?: string; limit?: number; offset?: number }
    >({
      query: ({ assessmentIdList, limit, offset }) =>
        `assessments?assessmentIdList=${assessmentIdList}&offset=${offset}&limit=${limit}`,
      providesTags: ['assessmentstructure'],
    }),

    exportCohort: builder.mutation<IExcelResponse, any>({
      query: (payload) => {
        return {
          url: 'export-calibration-report',
          method: 'POST',
          body: payload,
          responseHandler: async (response: any) => {
            // using any to avoid complex type compatibility issues
            if (response.status === 200) {
              downloadFile(
                await response.blob(),
                saveFileNameAsCSV('cohort summary')
              );
              return { status: 'success' };
            }
            if (response.status === 500) return response.json();
          },
          cache: 'no-cache',
        };
      },
    }),

    deleteProjectAssessments: builder.mutation<void, string>({
      query: (id) => ({
        url: `assessments/${id}`,
        method: 'DELETE',
      }),
    }),

    // templates
    getTemplates: builder.query<
      IAssessmentsStructureResponse,
      { clientId?: string; skillCategory?: string }
    >({
      query: ({ clientId, skillCategory }) =>
        `templates?clientId=${clientId}&skillCategory=${skillCategory}`,
      providesTags: ['templates'],
      transformResponse: (response: IAssessmentsStructureResponse) => {
        return {
          data: response.data,
          dropdownList: response.data.map((el) => ({
            keyName: el.title || '',
            keyValue: el.id || '',
          })),
        };
      },
    }),
  }),
});

export const assessmentStructureApiInvalidateTags =
  assessmentStructureApi.util.invalidateTags;

export const {
  useLazyGetAssessmentStructureByIdQuery,
  useGetAssessmentStructureByIdQuery,
  useGetAssessmentsStructureQuery,
  useLazyGetAssessmentsStructureQuery,
  useAddAssessmentStructureMutation,
  useEditAssessmentStructureMutation,
  useGetAssessmentsStructureByListQuery,
  useLazyGetAssessmentsStructureByListQuery,
  useExportCohortMutation,
  useGetTemplatesQuery,
  useLazyGetTemplatesQuery,
  useDeleteProjectAssessmentsMutation,
} = assessmentStructureApi;
